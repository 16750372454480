import React from 'react';
import ActivityPhoto from './ActivityPhoto';
import {withStyles} from "@material-ui/core/styles";
import LogoStrava from '../assets/api_logo_pwrdBy_strava_stack_light.svg';
import {Ellipsis} from "react-spinners-css";
import {FaRegClock, FaSpotify} from 'react-icons/fa';
import moment from "moment-timezone";

const CACHE_ACTIVITY_MINS = 30;

const useStyles = theme => ({
    panel: {
        zIndex: '2',
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        background: '#0D263B',
    },
    panelContent: {
        width: '100%',
        position: 'relative',
        margin: '-50px 30px 0 30px',
        top: '60px',
        background: '#fff',

        [theme.breakpoints.up('md')]: {
            width: '884px',
        }
    },
    photoMainContainer: {
        padding: '30px',

        [theme.breakpoints.up('md')]: {
            padding: '40px',
            display: 'flex',
            justifyContent: 'space-between',
        }
    },
    main: {
        width: '100%',

        [theme.breakpoints.up('md')]: {
            padding: '10px 0 0 60px',
        }
    },
    latest: {
        padding: '20px 0 10px 0',
        fontFamily: "\"futura_ptdemi\", sans-serif",
        textTransform: 'uppercase',
        fontSize: '1em',
        fontWeight: '700',
        color: '#FC4C02',
    },
    break: {
        width: '66px',
        margin: '20px 0 0 0',
        padding: '0 0 10px 0',
        borderTop: '2px #0D263B solid',
    },
    title: {
        fontFamily: "\"d-din_condenseddinCnBd\", sans-serif",
        textTransform: 'uppercase',
        fontSize: '3.4em',
        color: '#0D263B',
    },
    stats: {
        display: 'flex',
        fontFamily: "\"d-din_condensedregular\", sans-serif",
        fontSize: '1.4em',
        color: '#0D263B',
    },
    statsLogo: {
        padding: '1px 4px 0 0',
    },
    description: {
        fontFamily: "\"d-din_condensedregular\", sans-serif",
        fontSize: '1.4em',
        color: '#0D263B',
        padding: '10px 0 20px 0',
    },
    viewDetails: {
        fontFamily: "\"futura_ptdemi\", sans-serif",
        fontSize: '0.85',
        textTransform: 'uppercase',
        color: '#0D263B',

        '& a': {
            textDecoration: 'underline',
            color: '#0D263B',
        },

        '& a:hover': {
            textDecoration: 'none',
            color: '#0D263B',
        },
    },
    logoStrava: {
        width: '100px',
        padding: '20px 0 0 0',
        marginLeft: 'auto',
    },
    loaderContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }
});

class ActivityUpdatePanel extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
        activity: null,
      photoURL: null,
    }
  }

  componentDidMount() {
      let self = this;

      function getLatestActivityFromStrava() {
          // get activities
          fetch('/strava/athlete/' + self.props.athleteID + '/activities')
              .then(response => response.json())
              .then(data => {
                  if (!data.errors) {
                      const foundActivity = data.find(element => element.total_photo_count > 0 && element.type == 'Run');

                      if (foundActivity) {
                          // get activity
                          fetch('/strava/athlete/' + self.props.athleteID + '/activity/' + foundActivity.id)
                              .then(response => response.json())
                              .then(data => {
                                  self.setState({activity: data});
                                  self.setState({photoURL: data.photos.primary.urls[600]});
                              });
                      }
                  }
              });
      }

      // get latest activity
      fetch('/strava/latestactivity/' + this.props.athleteID)
          .then(response => response.json())
          .then(data => {
              if (data.name) {
                  moment.tz.setDefault("UTC");

                  let timeNowUTC = moment();
                  let timeNowUTCFormatted = timeNowUTC.format('YYYY-MM-DD HH:mm:ss');

                  let timeLastUpdated = moment(data.lastUpdated);
                  let timeLastUpdatedUTCFormatted = timeLastUpdated.format('YYYY-MM-DD HH:mm:ss');

                  let updatedAgo = moment(timeNowUTC).diff(data.lastUpdated, 'minutes');

                  // MLA - remove check to stop pulling in latest Strava activity

                  // less than tolerance use db
//                  if (updatedAgo < CACHE_ACTIVITY_MINS) {
                      self.setState({activity: data});
                      self.setState({photoURL: data.photo_url});
//                  }
//                  else {
//                      getLatestActivityFromStrava();
//                  }
              }
              else {
                  getLatestActivityFromStrava();
              }
          })
  }

  render() {
    const { classes } = this.props;
    let activity = this.state.activity;
    let photoURL = this.state.photoURL;
    let strDuration = 0;

    function RenderContent() {
        if (activity) {
            let totalSeconds = activity.elapsed_time;
            let hours = Math.floor(totalSeconds / 3600);
            totalSeconds %= 3600;
            let minutes = Math.floor(totalSeconds / 60);

            minutes = String(minutes).padStart(2, "0");
            hours = String(hours).padStart(2, "0");
            strDuration = hours + "h" + minutes;

            return (
                <div className={classes.panelContent}>
                    <div className={classes.photoMainContainer}>
                        <div className={classes.photo}>
                            <a href={'https://www.strava.com/activities/' + activity.id} target='_blank' rel='noreferrer'><ActivityPhoto photoURL={photoURL}/></a>
                        </div>

                        <div className={classes.main}>
                            <div className={classes.latest}>Latest update</div>
                            <div className={classes.title}>{activity.name}</div>
                            <div className={classes.stats}><span className={classes.statsLogo}><FaRegClock /></span>{strDuration}</div>
                            <div className={classes.break}></div>
                            <div className={classes.description}>{activity.description}</div>
                            <div className={classes.viewDetails}><a href={'https://www.strava.com/activities/' + activity.id} target='_blank' rel='noreferrer'>view on Strava</a></div>
                            <div className={classes.logoStrava}><a href='https://www.strava.com' target='_blank' rel='noreferrer'><img src={LogoStrava} /></a></div>
                        </div>
                    </div>
                </div>
            )

        }
        return (
            <div className={classes.panelContent}>
                <div className={classes.loaderContainer}><Ellipsis color="#FC4C02"/></div>
            </div>
        )
    }

    return (
        <div className={classes.panel}>
            <RenderContent />
        </div>
    )
  }
}

export default withStyles(useStyles)(ActivityUpdatePanel)