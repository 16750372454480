import React from 'react';
import {withStyles} from "@material-ui/core/styles";
//import SponsorPic from "../assets/sponsor.png";
import SponsorPic from "../assets/spencer-watson-dEOC8M_lmxI-unsplash.jpg";

const useStyles = theme => ({
    panel: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#0D263B',
    },
    panelContent: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '50px 32px',

        '& a': {
            textDecoration: 'underline',
            color: '#FFF',
        },

        '& a:hover': {
            textDecoration: 'none',
            color: '#FFF',
        },

        [theme.breakpoints.up('md')]: {
            width: '884px',
            padding: '90px 32px 70px 32px',
        }
    },
    image: {
        width: '100%',

        '& img': {
            width: '100%',
        }
    },
    description: {
        fontFamily: "\"d-din_condensedregular\", sans-serif",
        fontSize: '1.4em',
        lineHeight: '1.2em',
        color: '#FFF',
        padding: '20px 0 0 0',
        textAlign: 'center',

        [theme.breakpoints.up('md')]: {
            width: '328px',
        }
    },
});

class SponsorPanel extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  componentDidMount() {
  }

  render() {
    const { classes } = this.props;

//      <div className={classes.image}><a href='https://www.patagonia.com/' target='_blank' rel='noreferrer'><img src={SponsorPic} /></a></div>
//      <div className={classes.description}><a href='https://www.patagonia.com/' target='_blank' rel='noreferrer'>Patagonia</a> supports ALPRUN by sponsoring trees with <a href='https://trees.org/' target='_blank' rel='noreferrer'>Trees For The Future</a>.</div>

      return (
        <div className={classes.panel}>
            <div className={classes.panelContent}>

                <div className={classes.image}><img src={SponsorPic} /></div>
                <div className={classes.description}>Interested in being an ALPRUN tree sponsor?<br />Drop me a line to find out <a href='mailto:mallbeury@mac.com'>more</a>.</div>

            </div>
        </div>
     )
  }
}

export default withStyles(useStyles)(SponsorPanel)