import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import TextTransition, {presets} from "react-text-transition";
import Odometer from "react-odometerjs";
import 'odometer/themes/odometer-theme-default.css';
import moment from "moment-timezone";
import * as turf from "@turf/turf";

const useStyles = theme => ({
    panel: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        textAlign: 'center'
    },
    panelContent: {
        display: 'flex',
        position: 'relative',
        width: '100%',
        height: '114px',
        overflow: 'hidden',
        alignItems: 'center',
        justifyContent: 'center',

        [theme.breakpoints.up('md')]: {
            width: '884px',
            height: 'initial',
            padding: '40px 0',
        }
    },
    stats: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '240px',
        fontFamily: "\"d-din_condensedregular\", sans-serif",
        padding: '14px',
        color: '#FC4C02',
        background: '#FFF',

        [theme.breakpoints.up('md')]: {
            width: 'initial',
            padding: '0 30px',
        }
    },
    statsDivider: {
        display: 'inline',
        height: '66px',
        margin: '10px 0 0 0',
        padding: '0 10px 0 0',
        borderLeft: '2px solid #FC4C02',
    },
    detail: {
        paddingLeft: '14px',
        textAlign: 'left'
    },
    stat1: {
        fontWeight: '700',
        fontSize: '4.5em',
        lineHeight: '0.9em',

        [theme.breakpoints.up('md')]: {
            fontSize: '5.8em',
        },
    },
    stat2: {
        fontWeight: '700',
        fontSize: '2.6em',
        lineHeight: '0.9em',

        [theme.breakpoints.up('md')]: {
            fontSize: '3.4em',
        },
    },
    stat3: {
        fontWeight: '500',
        display: 'block',
        fontSize: '1.3em',
        lineHeight: '0.9em',

        [theme.breakpoints.up('md')]: {
            fontSize: '1.5em',
        },
    },
    statsMobile: {
        display: 'flex',
        position: 'absolute',
        top: '14px',

        [theme.breakpoints.up('md')]: {
            display: 'none',
        }
    },
    statsDesktop: {
        display: 'none',

        [theme.breakpoints.up('md')]: {
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
        }
    }
});

const TEXTS1 = [
    "--",
    "--",
    "--",
];

const TEXTS2 = [
    "%",
    "KM",
    "Day",
];

const TEXTS3 = [
    "completed",
    "of 1000 km",
    "/--",
];

class StatsProgressPanel extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
        index: 0
    }

    this.intervalID = null;
    this.bRouteProcessed = false;
    this.routePoints = [];
    this.fTotalLength = 0;
    this.percentProgress = 0;
    this.distanceProgress = 0;
    this.progressDays = 0;
    this.turfLine = null;
    this.fLocationLat = 0;
    this.fLocationLng = 0;
    this.fPrevLocationLat = 0;
    this.fPrevLocationLng = 0;
  }

  componentDidMount() {
      this.intervalID = setInterval(() => {
          let index = this.state.index;
          this.setState({index: index + 1});
      }, 3000);
  }

  componentWillUnmount() {
      clearInterval(this.intervalID);
  }

  render() {
      const { classes } = this.props;
      let self = this;
      let index = this.state.index;
      let stat2Class = classes.stat2;
      let stat3Class = classes.stat3;

      if (index == TEXTS2.length - 1) {
          stat2Class = classes.stat3;
          stat3Class = classes.stat2;
      }

      function calcLocation(currentLocation) {
          let bLocationUpdated = false;

          if (self.bRouteProcessed) {
              if (self.fLocationLat.toFixed(3) != currentLocation[0].toFixed(3) || self.fLocationLng.toFixed(3) != currentLocation[1].toFixed(3)) {
                  bLocationUpdated = true;

                  self.fPrevLocationLat = self.fLocationLat;
                  self.fPrevLocationLng = self.fLocationLng;

                  self.fLocationLat = currentLocation[0];
                  self.fLocationLng = currentLocation[1];

                  var pt = turf.point([currentLocation[1], currentLocation[0]]);
                  var snapped = turf.nearestPointOnLine(self.turfLine, pt, {units: 'kilometers'});

                  var start = turf.point([self.routePoints[0][0], self.routePoints[0][1]]);
                  var stop = turf.point([currentLocation[1], currentLocation[0]]);
                  var sliced = turf.lineSlice(start, stop, self.turfLine);

                  // update distance progress
                  self.distanceProgress = Math.round(turf.length(sliced, {units: 'kilometers'}));
                  console.log('DISTANCE SLICED:'+self.distanceProgress+' : '+self.fTotalLength);
                  TEXTS1[1] = Math.round(self.distanceProgress);
                  // update % progress
                  self.percentProgress = Math.round((self.distanceProgress / self.fTotalLength) * 100);
                  TEXTS1[0] = Math.round(self.percentProgress);
              }
              // calc day and update progress
              let startDate = moment(self.props.startDate).utc();
              let nowDate = moment.utc();

              let startDateFormatted = moment.tz(startDate, "CET").format('YYYY-MM-DD HH:mm');
              let nowDateFormatted = moment.tz(nowDate, "CET").format('YYYY-MM-DD HH:mm');

              let progressHours = moment(nowDate).diff(startDate, 'hours');
              let progressDays = Math.ceil(progressHours / 24);
              if (progressDays > self.props.totalDays) {
                  self.progressDays = self.props.totalDays;
              }
              else if (progressDays > 0) {
                  self.progressDays = progressDays;
              }

              TEXTS1[2] = Math.round(self.progressDays);
              TEXTS3[2] = "/" + self.props.totalDays;
          }
          return bLocationUpdated;
      }

      if (self.props.route.length && !self.bRouteProcessed) {
          self.bRouteProcessed = true;
          console.log('PROCESS ROUTE');

          self.props.route.forEach(function (point, index) {
              self.routePoints.push([point[1], point[0]]);
          })

          console.log('BUILD LINE');
          self.turfLine = turf.lineString(self.routePoints);
          var length = turf.length(self.turfLine, {units: 'kilometers'});
          console.log('DISTANCE:'+length);
          self.fTotalLength = Math.round(length);

          TEXTS3[1] = 'of ' + self.fTotalLength + ' km';
      }

      if (calcLocation(this.props.currentLocation)) {
          console.log('LOCATION UPDATED');
      }

    return (
        <div className={classes.panel}>
            <div className={classes.panelContent}>

                <div className={classes.statsDesktop}>
                    <div className={classes.stats}>
                        <span className={classes.stat1}><Odometer animation="count" format="(,ddd)" duration={ 1000 } value={ self.percentProgress }/></span>
                        <div className={classes.detail}>
                            <span className={classes.stat2}>{TEXTS2[0]}</span><span className={classes.stat3}>{TEXTS3[0]}</span>
                        </div>
                    </div>
                    <div className={classes.statsDivider}></div>
                    <div className={classes.stats}>
                        <span className={classes.stat1}><Odometer animation="count" duration={ 1000 } value={ self.distanceProgress }/></span>
                        <div className={classes.detail}>
                            <span className={classes.stat2}>{TEXTS2[1]}</span><span className={classes.stat3}>{TEXTS3[1]}</span>
                        </div>
                    </div>
                    <div className={classes.statsDivider}></div>
                    <div className={classes.stats}>
                        <span className={classes.stat1}><Odometer animation="count" duration={ 1000 } value={ self.progressDays }/></span>
                        <div className={classes.detail}>
                            <span className={classes.stat3}>{TEXTS2[2]}</span><span className={classes.stat2}>{TEXTS3[2]}</span>
                        </div>
                    </div>
                </div>

                <div className={classes.statsMobile}>
                    <TextTransition delay={3000} springConfig={presets.gentle}>
                        <div className={classes.stats}>
                            <span className={classes.stat1}>{TEXTS1[index % TEXTS1.length]}</span>
                            <div className={classes.detail}>
                                <span className={stat2Class}>{TEXTS2[index % TEXTS2.length]}</span><span className={stat3Class}>{TEXTS3[index % TEXTS3.length]}</span>
                            </div>
                        </div>
                    </TextTransition>
                </div>
            </div>
        </div>
    )
  }
}

export default withStyles(useStyles)(StatsProgressPanel)