import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import DonationPagePanel from './DonationPagePanel';
import FooterPanel from "./FooterPanel";

const useStyles = theme => ({
});

class DonationPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      config: null,
    };
  }

  componentDidMount() {
    let self = this;

    // get config
    fetch('/config')
        .then(response => response.json())
        .then(data => {
          self.setState({ config: data });
        })
  }
  
  render() {
    const { classes } = this.props;
    let self = this;

    if (this.state.config) {
      return (
          <div>
            <DonationPagePanel config={this.state.config} trees={self.props.match.params.trees}/>
            <FooterPanel/>
          </div>
      );
    }
    return (
        <div />
    )
  }
}

export default withStyles(useStyles)(DonationPage)
