import React from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { withStyles } from '@material-ui/core/styles';
import {Link, useHistory} from "react-router-dom";
import Background from '../assets/trees.png';
import { FaArrowCircleLeft } from 'react-icons/fa';

const muiTheme = createTheme({
    palette: {
        primary: {
            main: "#FC4C02",
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontFamily: "\"futura_ptdemi\", sans-serif",
                    fontSize: '1.2rem',
                    padding: '4px 30px',
                    borderRadius: '0',
                },
            }
        },
    }
});

const useStyles = theme => ({
    panel: {
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
        padding: '40px 0 50px 0',
        color: '#fff',
        background: '#0D263B',
        backgroundImage: 'url(' + Background + ')',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'bottom',
        backgroundSize: '100%',
        alignItems: 'center',
        justifyContent: 'center',

        [theme.breakpoints.up('md')]: {
            backgroundPosition: 'bottom right',
            backgroundSize: 'initial',
            padding: '50px 0 60px 0',
        }
    },
    panelContent: {
        padding: '0 32px',

        [theme.breakpoints.up('md')]: {
            width: '884px',
        }
    },
    taglineContainer: {
        display: 'flex',

        [theme.breakpoints.up('md')]: {
            justifyContent: 'space-between',
        }
    },
    tagline: {
        fontFamily: "\"d-din_condensedregular\", sans-serif",
        fontWeight: 300,
        fontSize: '3.4em',
        textTransform: 'uppercase',
        color: '#fff',
    },
    navBack: {
        fontSize: '3em',
        color: '#FFF',
        padding: '10px 0 0 10px',

        '& a': {
            textDecoration: 'none',
            color: '#FFF',
        },

        '& a:hover': {
            textDecoration: 'none',
            color: '#FFF',
        },
    },
    container: {
        '& a': {
            textDecoration: 'underline',
            color: '#FFF',
        },

        '& a:hover': {
            textDecoration: 'none',
            color: '#FFF',
        },

        [theme.breakpoints.up('md')]: {
            width: '600px',
        }
    },
    place: {
        padding: '10px 0',
        fontFamily: "\"futura_ptdemi\", sans-serif",
        textTransform: 'uppercase',
        fontSize: '0.85em',
    },
    description: {
        fontFamily: "\"d-din_condensedregular\", sans-serif",
        fontSize: '1.5em',
        lineHeight: '1.3em',
    }
});

class AboutPagePanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
  }
  
  render() {
    const { classes } = this.props;
    let homeLink = '/';

    return (
        <ThemeProvider theme={muiTheme}>
            <div className={classes.panel}>
                <div className={classes.panelContent}>

                    <div className={classes.taglineContainer}>
                        <div className={classes.tagline}>Planting trees<br />against climate change</div>
                        <div className={classes.navBack}><Link to={homeLink}><FaArrowCircleLeft /></Link></div>
                    </div>

                    <div className={classes.container}>

                        <div className={classes.place}>Start Zurich July 19 - finish Trieste August 17</div>
                        <div className={classes.description}>“Running unsupported, sleeping in forests, bathing in lakes, eating a lot..  and I mean a lot!<br /><br />More than just an indulgent endeavour, the plan is to raise funds for trees.  That's for the growing, planting and looking after of trees, hopefully thousands and thousands of trees!<br /><br />You can follow the project on <a href='https://www.instagram.com/mallbeury/' target='_blank' rel='noreferrer'>Instagram</a>, support it with a <a href="/donation/50">donation</a> (of course) or download the <a href='https://www.activegiving.de' target='_blank' rel='noreferrer'>Active Giving</a> app and get active yourself, your effort will plant trees without you putting your hand in your pocket, I know, sounds amazing right?<br /><br />Check out <a href='https://trees.org/' target='_blank' rel='noreferrer'>Trees for the Future</a> to find out more.”</div>

                    </div>

                </div>
            </div>
        </ThemeProvider>
    );
  }
}

export default withStyles(useStyles)(AboutPagePanel)