import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { FaSpotify, FaPodcast, FaMicrophone } from 'react-icons/fa';
import Podcast from '../assets/podcast_sfk.jpg';

const muiTheme = createTheme({
    palette: {
        primary: {
            main: "#FFF",
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontFamily: "\"futura_ptdemi\", sans-serif",
                    fontSize: '1rem',
                    padding: '4px 20px',
                    borderRadius: '0',
                },
            }
        }
    }
});

const useStyles = theme => ({
    panel: {
        display: 'flex',
        width: '100%',
        paddingTop: '30px',
        paddingBottom: '0px',
        justifyContent: 'center',
        color: '#fff',
        textAlign: 'center',

        [theme.breakpoints.up('md')]: {
            paddingTop: '30px',
            paddingBottom: '20px',
        }
    },
    panelContent: {
        width: '100%',
        padding: '30px',
        background: '#33536F',

        [theme.breakpoints.up('sm')]: {
            display: 'flex',
            justifyContent: 'space-between',
            padding: '16px',
        },

        [theme.breakpoints.up('md')]: {
            width: '648px',
        }
    },
    panelPodcastContent: {
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
        }
    },
    podcastPhoto: {
        '& img': {
            width: '100%',
        },

        [theme.breakpoints.up('sm')]: {
            '& img': {
                width: '148px',
            },
        },

        [theme.breakpoints.up('md')]: {
            '& img': {
                width: '164px',
            },
        },
    },
    podcastText: {
        padding: '12px 0 0 0',
        textAlign: 'left',

        [theme.breakpoints.up('sm')]: {
            padding: '12px 0px 0 30px',
        },
    },
    title: {
        fontFamily: "\"futura_ptbold\", sans-serif",
        textTransform: 'uppercase',
        fontSize: '1em',
    },
    host: {
        padding: '10px 0',
        fontFamily: "\"futura_ptdemi\", sans-serif",
        textTransform: 'uppercase',
        fontSize: '0.85em',
    },
    description: {
        fontFamily: "\"d-din_condensedregular\", sans-serif",
        fontSize: '1.4em',
        lineHeight: '1.2em',
    },
    linkPodcastLogoContainer: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    linkContainer: {
        fontFamily: "\"futura_ptdemi\", sans-serif",
        textTransform: 'uppercase',
        fontSize: '0.85em',
        paddingTop: '20px',

        '& a:first-child': {
        },

        '& a': {
            textDecoration: 'underline',
            color: '#FFF',
        },

        '& a:hover': {
            textDecoration: 'none',
            color: '#FFF',
        },

        [theme.breakpoints.up('sm')]: {
            display: 'flex',
            paddingTop: '4px',
        },

        [theme.breakpoints.up('md')]: {
        },
    },
    link: {
        paddingRight: '10px',

        '& a': {
            display: 'flex',
            alignItems: 'center',
        },

        [theme.breakpoints.up('sm')]: {
            justifyContent: 'center',
        },
    },
    linkLogo: {
        fontSize: '1.6em',
        padding: '4px 8px 0 0',
    },
    podcastLogoMobile: {
        paddingTop: '20px',
        fontSize: '3.7em',
        lineHeight: '1em',
        color: '#67829B',

        [theme.breakpoints.up('sm')]: {
            display: 'none',
        }
    },
    podcastLogoDesktop: {
        display: 'none',
        paddingTop: '12px',
        fontSize: '3.7em',
        lineHeight: '1em',
        color: '#67829B',

        [theme.breakpoints.up('sm')]: {
            display: 'block',
        }
    }
});

class PodcastPanel extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  componentDidMount() {
  }

  render() {
    const { classes } = this.props;

    return (
        <ThemeProvider theme={muiTheme}>

        <div className={classes.panel}>
            <div className={classes.panelContent}>

                <div className={classes.panelPodcastContent}>
                    <div className={classes.podcastPhoto}><img src={Podcast} /></div>
                    <div className={classes.podcastText}>
                        <div className={classes.title}>The importance of connecting with nature</div>
                        <div className={classes.host}>podcast hosted by Anhely Millan</div>
                        <div className={classes.description}>"Please join me on this nurturing interview and learn how to enjoy nature, appreciate and take care of our environment and how spending time outside can heal our minds and bodies."</div>

                        {/*
                        <div className={classes.title}>The Trail Runners Experience #83</div>
                        <div className={classes.host}>podcast hosted by Daniel Farrugia</div>
                        <div className={classes.description}>"I always enjoy my conversations on this podcast but I must say that I feel extremely inspired by Matt as a person and by this incredible feat he is about to undertake."</div>
                        */}

                        <div className={classes.linkPodcastLogoContainer}>

                            <div className={classes.linkContainer}>
                                <div className={classes.link}><a href='https://open.spotify.com/episode/2ANUhgn2BhIHH6ezkix2Vd?si=b5a51efb1aa748f4' target='_blank' rel='noreferrer'><span className={classes.linkLogo}><FaSpotify /></span>Listen on spotify</a></div>
                                {/*
                                <div className={classes.link}><a href='https://open.spotify.com/episode/6LSnYEvZYLpUyfB6spKSWb?si=7Vr2fHFqT6-7ZZP-b3H4OQ' target='_blank' rel='noreferrer'><span className={classes.linkLogo}><FaSpotify /></span>Listen on spotify</a></div>
                                <div className={classes.link}><a href='https://podcasts.apple.com/au/podcast/83-ft-matt-allbeury/id1398280406?i=1000607302748' target='_blank' rel='noreferrer'><span className={classes.linkLogo}><FaPodcast /></span> Listen on apple</a></div>
                                */}
                            </div>
                            <div className={classes.podcastLogoMobile}><FaMicrophone /></div>

                        </div>

                    </div>
                </div>
                <div className={classes.podcastLogoDesktop}><FaMicrophone /></div>

            </div>
        </div>

        </ThemeProvider>
    )
  }
}

export default withStyles(useStyles)(PodcastPanel)