import React from 'react';
import { withStyles } from '@material-ui/core/styles';
//import StravaConnectBtn from './StravaConnectBtn';
import HeroPanel from './HeroPanel';
import StatsPanel from './StatsPanel';
import AboutPanel from './AboutPanel';
import ActivityUpdatePanel from './ActivityUpdatePanel';
import DonatePanel from './DonatePanel';
import LocationPanel from './LocationPanel';
import ActiveGivingPanel from './ActiveGivingPanel';
import SponsorPanel from './SponsorPanel';
import FooterPanel from './FooterPanel';

const useStyles = theme => ({
    branding: {
        display: 'flex',
        flexWrap: 'wrap',
    },
});

class WelcomePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        config: null,
        totalTrees: 0
    };
  }

  componentDidMount() {
      let self = this;

      // get config
      fetch('/config')
          .then(response => response.json())
          .then(data => {
              console.log(data);
              self.setState({ config: data });
          })

      // get donations
      fetch('/api/getalldonations')
          .then(response => response.json())
          .then(data => {
              self.setState({ totalTrees: data.amount });
        })
  }
  
  render() {
    const { classes } = this.props;
    let self = this;
    let trees = 50;
    if (this.props.match.params.trees) {
        trees = this.props.match.params.trees;
    }
    let athleteID = 270394;

    if (this.state.config) {
        return (
            <div className={classes.branding}>
                <HeroPanel treeGoal={this.state.config.treeGoal} totalTrees={this.state.totalTrees} />
                <StatsPanel treeGoal={this.state.config.treeGoal} />
                <AboutPanel treeGoal={this.state.config.treeGoal} />
                <ActivityUpdatePanel athleteID={athleteID} />
                <LocationPanel lastKnownLocation={this.state.config.lastKnownLocation} startDate={this.state.config.startDate} totalDays={this.state.config.totalDays} />
                <DonatePanel localCurrency={this.state.config.localCurrency} treeGoal={this.state.config.treeGoal} totalTrees={this.state.totalTrees} trees={trees} />
                <ActiveGivingPanel />
                <SponsorPanel />
                <FooterPanel />
            </div>
        );
    }
    return (
        <div />
    )
  }
}
/*

        <div>

            {athlete &&
            <div>
                <strong><p>Hello {athlete.firstname}</p></strong>

                <Activities athlete={athlete} />

                <p><span onClick={this.onSignout}>Sign out</span></p>

            </div>
            }

            {!athlete &&
            <StravaConnectBtn />
            }
        </div>
*/

export default withStyles(useStyles)(WelcomePage)
