import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import AboutPagePanel from './AboutPagePanel';
import FooterPanel from "./FooterPanel";

const useStyles = theme => ({
});

class AboutPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
  }
  
  render() {
    const { classes } = this.props;
    let self = this;

      return (
          <div>
            <AboutPagePanel />
            <FooterPanel/>
          </div>
      );
  }
}

export default withStyles(useStyles)(AboutPage)
