import React from 'react';
import mapboxgl from '!mapbox-gl';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import {withStyles} from "@material-ui/core/styles";
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

mapboxgl.accessToken = 'pk.eyJ1IjoibWFsbGJldXJ5IiwiYSI6IjJfV1MzaE0ifQ.scrjDE31p7wBx7-GemqV3A';

const VIEW_LIVE_OVERVIEW = 0;
const VIEW_LIVE_DETAIL = 1;
const VIEW_ROUTE_OVERVIEW = 2;

const VIEW_LIVE_ZOOM = 13;

const FLY_DURATION = 6000;

const muiTheme = createTheme({
  palette: {
    primary: {
      main: "#FC4C02",
    },
  },
});

const StyledFormControlLabel = withStyles(() => ({
  label: {
    color: '#FFF',
  }
}))(FormControlLabel);

const useStyles = theme => ({
  panel: {
    width: '100%',

    position: 'relative',
  },
  mapContainer: {
    width: '100%',
    height: '460px',
  },
  nav: {
    position: 'absolute',
    bottom: '32px',
    right: '32px',

    [theme.breakpoints.up('md')]: {
      left: '50%',
      marginLeft: '362px',
    }
  },
})

class LocationMapPanel extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      view: VIEW_LIVE_OVERVIEW,
      viewToggle: true,
      currentLocation: [],
      activities: []
    };

    this.mapContainer = React.createRef();
    this.map = null;
    this.bMapReady = false;
    this.bLocationShown = false;
    this.bRouteAdded = false;
    this.fLocationLat = 0;
    this.fLocationLng = 0;
    this.routePoints = [];
    this.stageNums = [];
    this.prevView = VIEW_LIVE_OVERVIEW;
  }

  componentDidMount() {
    let self = this;

    function buildCurrentLocationDot() {
      const size = 150;

      // This implements `StyleImageInterface`
      // to draw a pulsing dot icon on the map.
      const pulsingDot = {
        width: size,
        height: size,
        data: new Uint8Array(size * size * 4),

        // When the layer is added to the map,
        // get the rendering context for the map canvas.
        onAdd: function () {
          const canvas = document.createElement('canvas');
          canvas.width = this.width;
          canvas.height = this.height;
          this.context = canvas.getContext('2d');
        },

        // Call once before every frame where the icon will be used.
        render: function () {
          const duration = 1000;
          const t = (performance.now() % duration) / duration;

          const radius = (size / 2) * 0.3;
          const outerRadius = (size / 2) * 0.7 * t + radius;
          const context = this.context;

          // Draw the outer circle.
          context.clearRect(0, 0, this.width, this.height);
          context.beginPath();
          context.arc(
              this.width / 2,
              this.height / 2,
              outerRadius,
              0,
              Math.PI * 2
          );
          context.fillStyle = `rgba(252, 76, 02, ${1 - t})`;
          context.fill();

          // Draw the inner circle.
          context.beginPath();
          context.arc(
              this.width / 2,
              this.height / 2,
              radius,
              0,
              Math.PI * 2
          );
          context.fillStyle = 'rgba(255, 100, 100, 1)';
          context.strokeStyle = 'white';
          context.lineWidth = 2 + 4 * (1 - t);
          context.fill();
          context.stroke();

          // Update this image's data with data from the canvas.
          this.data = context.getImageData(
              0,
              0,
              this.width,
              this.height
          ).data;

          // Continuously repaint the map, resulting
          // in the smooth animation of the dot.
          self.map.triggerRepaint();
          console.log('triggerRepaint');

          // Return `true` to let the map know that the image was updated.
          return pulsingDot;
        }
      }
      return pulsingDot
    }

    function buildMap(mapContainer, pointLocation) {
      var map = new mapboxgl.Map({
        container: mapContainer,
        style: 'mapbox://styles/mapbox-map-design/ckhqrf2tz0dt119ny6azh975y',
        center: pointLocation,
        pitch: 60, // pitch in degrees
        bearing: 0, // bearing in degrees
        zoom: VIEW_LIVE_ZOOM
      });

      map.scrollZoom.disable();
      map.dragRotate.disable();
      map.dragPan.disable();
      map.touchZoomRotate.disable();

      map.addImage('pulsing-dot', buildCurrentLocationDot(), { pixelRatio: 2 });

      return map;
    }

    self.map = buildMap(this.mapContainer.current, [self.props.lastKnownLocation.Longitude, self.props.lastKnownLocation.Latitude]);
  }

  componentWillUnmount() {
    console.log('LocationMap componentWillUnmount');
    this.map.removeImage('pulsing-dot');
  }

  render() {
    const { classes } = this.props;
    let self = this;
    
    function addStages(markerPoint) {
      function AddPosMarker(point, strPos, nMinZoom) {
        const el = document.createElement('div');
        el.className = 'marker';

        var marker = new mapboxgl.Marker(el).setLngLat(markerPoint).addTo(self.map);

        var elNum = document.createElement('div');
        self.stageNums.push(elNum);
        elNum.setAttribute('minZoom', nMinZoom);
        elNum.className = 'marker-pos';
        elNum.innerHTML += '<div class="pos" style="background: #FC4C02">' + strPos + '</div>';

        var numMarker = new mapboxgl.Marker(elNum)
            .setLngLat(point)
            .setOffset([0, 0]);
        numMarker.addTo(self.map);
      }

      // add stages
      var stages = [
        {
          'name': 'Zurich',
          'num': '1',
          'lat': 47.39232769087037,
          'lon': 8.516095350906383
        },
        {
          'name': 'Restaurant Grynau',
          'num': '2',
          'lat': 47.21658932596304,
          'lon': 8.970343619017852
        },
        {
          'name': 'Campingplatz Schafbergblick',
          'num': '3',
          'lat': 47.20039226905493,
          'lon': 9.360906105699609
        },
        {
          'name': 'Hochblanken',
          'num': '4',
          'lat': 47.31218830853518,
          'lon': 9.869055406354684
        },
        {
          'name': 'B40 - Oberstdorf',
          'num': '5',
          'lat': 47.4037680000,
          'lon': 10.2862960000
        },
        {
          'name': 'B38 - Holzgau',
          'num': '6',
          'lat': 47.2595730000,
          'lon': 10.3436570000
        },
        {
          'name': 'B36 - Zams am Inn',
          'num': '7',
          'lat': 47.1579800000,
          'lon': 10.5896740000
        },
        {
          'name': 'B35 - Wenns',
          'num': '8',
          'lat': 47.1691730000,
          'lon': 10.7308720000
        },
        {
          'name': 'B34 - Braunschweiger Hütte',
          'num': '9',
          'lat': 46.9353780000,
          'lon': 10.9096360000
        },
        {
          'name': 'B32 - Vent',
          'num': '10',
          'lat': 46.8599660000,
          'lon': 10.9136420000
        },
        {
          'name': 'B30 - Karthaus',
          'num': '11',
          'lat': 46.7035330000,
          'lon': 10.9041470000
        },
        {
          'name': 'B28 - Hochganghaus',
          'num': '12',
          'lat': 46.7125610000,
          'lon': 11.0866640000
        },
        {
          'name': 'B27 - Meraner Hütte',
          'num': '13',
          'lat': 46.6798300000,
          'lon': 11.2510300000
        },
        {
          'name': 'B26 - Bozen',
          'num': '14',
          'lat': 46.5092630000,
          'lon': 11.3499070000
        },
        {
          'name': 'B24 - Rifugio Antermoia',
          'num': '15',
          'lat': 46.4762900000,
          'lon': 11.6685100000
        },
        {
          'name': 'B22 - Rifugio Contrin',
          'num': '16',
          'lat': 46.4257720000,
          'lon': 11.8165070000
        },
        {
          'name': 'B21 - Passo Pordoi',
          'num': '17',
          'lat': 46.4871060000,
          'lon': 11.8121910000
        },
        {
          'name': 'B20 - Pieve di Livinallongo',
          'num': '18',
          'lat': 46.4804870000,
          'lon': 11.9504640000
        },
        {
          'name': 'B18 - Rifugio P. Galassi',
          'num': '19',
          'lat': 46.4696700000,
          'lon': 12.2615150000
        },
        {
          'name': 'B16 - Rifugio Pordenone',
          'num': '20',
          'lat': 46.3799780000,
          'lon': 12.4919700000
        },
        {
          'name': 'B14 - Sauris di Sotto',
          'num': '21',
          'lat': 46.4771180000,
          'lon': 12.6984790000
        },
        {
          'name': 'B13 - Ovaro',
          'num': '22',
          'lat': 46.4809770000,
          'lon': 12.8656820000
        },
        {
          'name': 'B12 - Tolmezzo',
          'num': '23',
          'lat': 46.4012180000,
          'lon': 13.0195950000
        },
        {
          'name': 'B11 - Rifugio Grauzaria',
          'num': '24',
          'lat': 46.4742010000,
          'lon': 13.1508920000
        },
        {
          'name': 'B10 - Resiutta',
          'num': '25',
          'lat': 46.3919380000,
          'lon': 13.2184410000
        },
        {
          'name': 'B9 - Passo di Tanamea',
          'num': '26',
          'lat': 46.3057500000,
          'lon': 13.3518420000
        },
        {
          'name': 'B7 - Rif. G. Pelizzo',
          'num': '27',
          'lat': 46.2059330000,
          'lon': 13.5424720000
        },
        {
          'name': 'B5 - Castelmonte / Stara Gora',
          'num': '28',
          'lat': 46.0925640000,
          'lon': 13.5188660000
        },
        {
          'name': 'B3 - Sistiana / Sesljan',
          'num': '29',
          'lat': 45.7696420000,
          'lon': 13.6363590000
        },
        {
          'name': 'B2 - Villa Opicina',
          'num': '30',
          'lat': 45.6799800000,
          'lon': 13.7810610000
        }
      ]

      var nStages = 30;
      var nStep = Math.round(self.routePoints.length / (nStages));

      var nMinZoom = 0;

      stages.forEach(function (stage, index) {
        var nCurrentStage = index + 1;
        var nCurrentStep = nStep * (nCurrentStage - 1);
        nMinZoom = 8;
        if (nCurrentStage % 5 == 0) {
          nMinZoom = 6;
        }
        if (nCurrentStage % 10 == 0) {
          nMinZoom = 4;
        }
        if (nCurrentStage == 1) {
          nMinZoom = 2;
        }
        AddPosMarker([stage.lon, stage.lat], stage.num, nMinZoom);
      });
    }

    function showRoute(route) {
      if (self.props.route.length && !self.bRouteAdded) {
        self.bRouteAdded = true;

        self.map.on('load', () => {
          self.map.addSource('mapbox-dem', {
            'type': 'raster-dem',
            'url': 'mapbox://mapbox.mapbox-terrain-dem-v1',
            'tileSize': 512,
            'maxzoom': 15
          });

          // add the DEM source as a terrain layer with exaggerated height
          self.map.setTerrain({ 'source': 'mapbox-dem', 'exaggeration': 1.5 });
          self.map.setFog({});

          self.props.route.forEach(function (point, index) {
            self.routePoints.push([point[1], point[0]]);
          })

          self.map.addLayer({
            "id": "route",
            "type": "line",
            "source": {
              "type": "geojson",
              "data": {
                "type": "Feature",
                "properties": {},
                "geometry": {
                  "type": "LineString",
                  "coordinates": self.routePoints
                }
              }
            },
            "layout": {
              "line-join": "round",
              "line-cap": "round"
            },
            "paint": {
              "line-color": "#f75f36",
              "line-width": 3
            }
          });

          self.bMapReady = true;

          if (!self.bLocationShown) {
            showLocation([self.fLocationLat, self.fLocationLng]);
          }

          // destination marker
          let markerPoint = self.routePoints[self.routePoints.length-1];

          // stage markers
          addStages(markerPoint);

          const el = document.createElement('div');
          el.className = 'marker';
          new mapboxgl.Marker(el).setLngLat(markerPoint).addTo(self.map);
          
          self.map.on('zoom', () => {
            self.stageNums.forEach(function (elNum, index) {
              if (elNum.getAttribute('minZoom') < self.map.getZoom()) {
                elNum.style.visibility = 'visible';
              }
              else {
                elNum.style.visibility='hidden'
              }
            })
          });
        });
      }
    }
    showRoute(this.props.route);

    function updateView() {
      switch (self.state.view) {
        case VIEW_LIVE_OVERVIEW:
        case VIEW_LIVE_DETAIL:
          self.map.flyTo({
            duration: FLY_DURATION,
            center: [self.fLocationLng, self.fLocationLat],
            zoom: VIEW_LIVE_ZOOM
          });
          break;

        case VIEW_ROUTE_OVERVIEW:
          var locationPoint = self.routePoints[0];
          const bounds = new mapboxgl.LngLatBounds(locationPoint, locationPoint);
          for (const coord of self.routePoints) {
            bounds.extend(coord);
          }

          self.map.fitBounds(bounds, {
            padding: 40,
            pitch: 60,
            bearing: 0,
          });

      }
    }

    function showLocation(currentLocation) {
      self.fLocationLat = currentLocation[0];
      self.fLocationLng = currentLocation[1];

      if (self.bMapReady) {
        self.bLocationShown = true;

        if (self.map.getSource('dot-point')) {
          self.map.removeLayer('layer-with-pulsing-dot');
          self.map.removeSource('dot-point');
        }

        self.map.addSource('dot-point', {
          'type': 'geojson',
          'data': {
            'type': 'FeatureCollection',
            'features': [{
              'type': 'Feature',
              'geometry': {
                'type': 'Point',
                'coordinates': [self.fLocationLng, self.fLocationLat] // icon position [lng, lat]
              }
            }]
          }
        });

        self.map.addLayer({
          'id': 'layer-with-pulsing-dot',
          'type': 'symbol',
          'source': 'dot-point',
          'layout': {
            'icon-image': 'pulsing-dot'
          }
        });

        // track location
        updateView();
      }
    }
    console.log('showLocation');
    showLocation(this.props.currentLocation);

    function onToggleView() {
      switch (self.state.view) {
        case VIEW_LIVE_OVERVIEW:
          self.setState({ view: VIEW_ROUTE_OVERVIEW });
          self.setState({ viewToggle: false });
          break;

        case VIEW_ROUTE_OVERVIEW:
          self.setState({ view: VIEW_LIVE_OVERVIEW });
          self.setState({ viewToggle: true });
          break;
      }
    }

    function onChangeView() {
      switch (self.state.view) {
        case VIEW_LIVE_OVERVIEW:
          self.setState({ view: VIEW_LIVE_DETAIL });
          break;

        case VIEW_LIVE_DETAIL:
          self.setState({ view: VIEW_ROUTE_OVERVIEW });
          break;

        case VIEW_ROUTE_OVERVIEW:
          self.setState({ view: VIEW_LIVE_OVERVIEW });
          break;
      }
    }

    function handleClick() {
      self.map.scrollZoom.enable();
      self.map.dragRotate.enable();
      self.map.dragPan.enable();
      self.map.touchZoomRotate.enable();
    }

    // has the view changed?
    if (this.bMapReady && this.state.view != this.prevView) {
      updateView();
/*
      switch (this.state.view) {
        case VIEW_LIVE_OVERVIEW:
          self.map.flyTo({
            center: [self.fLocationLng, self.fLocationLat],
            pitch: 60,
            bearing: 0,
            duration: FLY_DURATION,
            zoom: VIEW_INITIAL_ZOOM});
          break;

        case VIEW_LIVE_DETAIL:
          self.map.flyTo({
            center: [self.fLocationLng, self.fLocationLat],
            pitch: 60,
            bearing: 0,
            duration: FLY_DURATION,
            zoom: 15});
          break;

        case VIEW_ROUTE_OVERVIEW:
          var locationPoint = self.routePoints[0];
          const bounds = new mapboxgl.LngLatBounds(locationPoint, locationPoint);
          for (const coord of self.routePoints) {
            bounds.extend(coord);
          }

          self.map.fitBounds(bounds, {
            padding: 40,
            pitch: 60,
            bearing: 0,
          });
          break;
      }
 */
    }
    this.prevView = this.state.view;

    return (
        <ThemeProvider theme={muiTheme}>

        <div className={classes.panel} onClick={handleClick}>
          <div ref={this.mapContainer} className={classes.mapContainer}/>
          <div className={classes.nav}>
            <StyledFormControlLabel control={<Switch checked={self.state.viewToggle} onChange={onToggleView} />} label="LIVE" />
          </div>
        </div>

        </ThemeProvider>
    )
  }
}

export default withStyles(useStyles)(LocationMapPanel)