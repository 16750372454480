import React from 'react';
import qs from 'query-string';
import { withStyles } from '@material-ui/core/styles';
import Stats from './Stats';
import Logo from '../assets/logo.png';
import Background from '../assets/chris-holgersson-M34n3mIUKS8-unsplash.jpg';
import FollowImage from '../assets/matt.png';
import { FaLinkedin, FaStrava } from 'react-icons/fa';

const useStyles = theme => ({
    branding: {
        backgroundImage: 'url(' + Background + ')',
        backgroundSize: 'cover',
        backgroundRepeat: 'none',
        backgroundPosition: 'center',
        background: '#000',
        height: '100%',
        minHeight: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    effect: {
        display: 'flex',
        height: '100%',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'linear-gradient(71.74deg, #0D263B -3.15%, rgba(13, 38, 59, 0) 75%)',

        [theme.breakpoints.up('sm')]: {
            background: 'linear-gradient(71.74deg, #0D263B -3.15%, rgba(13, 38, 59, 0) 55%)',
        },
    },
    headerContainer: {
        position: 'absolute',
        top: '28px',
        left: '0px',
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
    },
    header: {
        display: 'flex',
        flexWrap: 'wrap',
        width: '364px',
        padding: '0 32px',

        [theme.breakpoints.up('sm')]: {
            flexWrap: 'none',
            width: '576px',
        },

        [theme.breakpoints.up('md')]: {
            width: '680px',
        }
    },
    sticker: {
        marginLeft: 'auto',
    },
    logo: {
        width: '231px',

        [theme.breakpoints.up('md')]: {
            width: '238px'
        }
    },
    messaging: {
        width: '364px',
        padding: '0 32px',

        [theme.breakpoints.up('sm')]: {
            width: '576px',
        },

        [theme.breakpoints.up('md')]: {
            width: '680px',
        }
    },
    taglineContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    tagline: {
        paddingTop: '14px',
        fontFamily: "\"d-din_condensedregular\", sans-serif",
        fontWeight: 300,
        fontSize: '3em',
        textTransform: 'uppercase',
        color: '#fff',

        [theme.breakpoints.up('sm')]: {
            fontSize: '4em',
        },

        [theme.breakpoints.up('md')]: {
            fontSize: '5em',
        }
    },
    titles: {
        padding: theme.spacing(2),
        fontFamily: "\"Montserrat\", sans-serif",
        fontWeight: 700,
        fontSize: '1.4em'
    },
    followContainer: {
        position: 'absolute',
    },
    followImage: {
        alignSelf: 'self-start',
        width: '76px',

        [theme.breakpoints.up('sm')]: {
            width: '128px',
        },
    },
    followSubtext: {
        color: '#fff',
        fontFamily: "\"futura_ptdemi\", sans-serif",
        textTransform: 'uppercase',
    },
    followIcons: {
        paddingTop: '12px',
        fontSize: '2em',
        color: '#fff',

        '& a': {
            paddingRight: '8px',
            color: '#fff',
            textDecoration: 'none'
        },
    },
});

class HoldingPage extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const parsed = qs.parse(this.props.location.search);
  }

  render() {
      const { classes } = this.props;

    return (
        <div className={classes.branding}>
            <div className={classes.effect}>
                <div className={classes.headerContainer}>
                    <div className={classes.header}>
                        <div><img className={classes.logo} src={Logo} alt="PROJECT ALPRUN" /></div>
                    </div>
                </div>

                <div className={classes.messaging}>
                    <div className={classes.taglineContainer}>
                        <img className={classes.followImage} src={FollowImage} alt="Matt Allbeury" />
                        <div className={classes.tagline}>Planting trees<br/>against climate change</div>
                    </div>

                    <Stats />

                    <div className={classes.followContainer}>
                        <div className={classes.followSubtext}>Follow Matt's journey in July 2023!</div>
                        <div className={classes.followIcons}>
                            <a href="https://www.linkedin.com/in/matt-allbeury/" target="_blank"><FaLinkedin /></a>
                            <a href="https://www.strava.com/athletes/270394" target="_blank"><FaStrava /></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
  }
}

export default withStyles(useStyles)(HoldingPage)
