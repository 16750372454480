import React from 'react';
import TextTransition, { presets } from "react-text-transition";
import { withStyles } from '@material-ui/core/styles';

const useStyles = theme => ({
  stats: {
    display: 'flex',
    fontFamily: "\"d-din_condensedregular\", sans-serif",
    padding: '14px',
    color: '#0D263B',
    background: '#fff',
  },
  detail: {
    paddingLeft: '14px',
    textAlign: 'left'
  },
  stat1: {
    fontWeight: '700',
    fontSize: '1.8em',
    lineHeight: '0.9em',

    [theme.breakpoints.up('sm')]: {
      fontSize: '2.8em',
    },
  },
  stat2: {
    fontWeight: '700',
    fontSize: '1em',
    lineHeight: '1.1em',

    [theme.breakpoints.up('sm')]: {
      fontSize: '1.6em',
    },
  },
  stat3: {
    fontWeight: '500',
    display: 'block',
    fontSize: '0.5em',
    lineHeight: '0.8em',

    [theme.breakpoints.up('sm')]: {
      fontSize: '0.7em',
    },
  }
});

const TEXTS1 = [
  "30",
  "1,000",
  "50,000",
  "2023",
];

const TEXTS2 = [
  "DAYS",
  "KM",
  "TREES",
  "JULY",
];

const TEXTS3 = [
  "in the Alps",
  "to run",
  "to plant",
  "19th",
];

class Stats extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      index: 0
    };
  }

  componentDidMount() {
    setInterval(() => {
      let index = this.state.index;
      this.setState({index: index + 1});
    }, 3000);
  }

  render() {
    const { classes } = this.props;
    let index = this.state.index;

    return (
        <h1>
          <TextTransition delay={3000} springConfig={presets.gentle}>
            <div className={classes.stats}>
            <span className={classes.stat1}>{TEXTS1[index % TEXTS1.length]}</span>
            <div className={classes.detail}>
              <span className={classes.stat2}>{TEXTS2[index % TEXTS2.length]}</span><span className={classes.stat3}>{TEXTS3[index % TEXTS3.length]}</span>
            </div>
            </div>
          </TextTransition>
        </h1>
    )
  }
}

export default withStyles(useStyles)(Stats)
