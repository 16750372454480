import React, { useEffect } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import {withStyles} from "@material-ui/core/styles";
import { Ellipsis } from 'react-spinners-css';

const muiTheme = createTheme({
  palette: {
    primary: {
      main: "#FC4C02",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: "\"futura_ptdemi\", sans-serif",
          fontSize: '1.2rem',
          padding: '4px 30px',
        },        
      }
    },
  }
});

const useStyles = theme => ({
  container: {
    [theme.breakpoints.up('md')]: {
      width: '600px',
    }
  },
  tagsubline: {
    padding: '0 0 20px 0',
    fontFamily: "\"d-din_condensedregular\", sans-serif",
    fontWeight: 300,
    fontSize: '1.4em',
    color: '#fff',

    '& a': {
      textDecoration: 'underline',
      color: '#FFF',
    },

    '& a:hover': {
      textDecoration: 'none',
      color: '#FFF',
    },
  },
  loaderContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
});

class DonationWidget extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    let localCurrency = this.props.config.localCurrency;
    let donation = (this.props.trees * localCurrency.cost).toFixed(2);

    let currencies = [];

    this.props.config.currencies.forEach(function (item, index) {
      currencies.push(item.currency);
    });

    const handleLoad = (evt) => {
      console.log('handleLoad');

      window.rnw.tamaro.events.afterCreate.subscribe((event) => {
        const api = event.data.api
        const toJS = window.rnw.tamaro.toJS

        const showBlocks = toJS(api.config.showBlocks) || {}
//        showBlocks.payment_profile = false
        showBlocks.payment_purposes = false
        showBlocks.slot_fundraiser = true
        api.config.showBlocks = showBlocks

        const forceShowBlocks = toJS(api.config.forceShowBlocks) || {}
//        forceShowBlocks.payment_profile = false
        api.config.forceShowBlocks = forceShowBlocks;
      })

      window.rnw.tamaro.events.beforePaymentSend.subscribe((event) => {
        console.log('beforePaymentSend');
        console.log(event);
      })

      window.rnw.tamaro.events.paymentValidateError.subscribe((event) => {
        console.log('paymentValidateError');
        console.log(event);
      })

      window.rnw.tamaro.events.paymentComplete.subscribe((event) => {
        console.log('paymentComplete');
        console.log(event);
      })

      window.rnw.tamaro.runWidget('.rnw-widget-container', {
        testMode: false,
        debug: false,
        language: 'en',
        amounts: [donation],
        currencies: currencies,
        defaultCurrency: localCurrency.currency,
        autoselectCurrency: true,
//        paymentMethods: ['card'],
//        defaultPaymentMethod: 'card',
        recurringIntervals: ['monthly'],
        paymentFormPrefill: {
          stored_campaign: 'ALPRUN',
          stored_accept_fundraiser_subscribe: true
        },
        showFields: {
          stored_customer_birthdate: false,
          stored_customer_message: false,
          stored_customer_email_permission: false
        },
        translations: {
          en: {
            slot_fundraiser: {
              block_title: "PROJECT ALPRUN updates",
              stored_accept_fundraiser_subscribe: "Receive email updates from Matt during PROJECT ALPRUN",
            },
            purposes: {
              p1: 'Trees for the Future',
            },
            currencies: {
              gbp: "British pound",
              aud: "Australian Dollar"
            }
          }
        },
        slots: {
          slot_fundraiser: [{
            component: "block",
            children: [{
              component: "block_header",
              title: "slot_fundraiser.block_title",
            },{
              component: "block_content",
              children: [{
                component: "field",
                type: "checkbox",
                formName: "payment_form",
                name: "stored_accept_fundraiser_subscribe",
                text: "slot_fundraiser.stored_accept_fundraiser_subscribe"
              }],
            }],
          }],
        }
      })
    }

    const script = document.createElement("script");
    script.addEventListener('load', handleLoad)
    script.src = "https://tamaro.raisenow.com/treesforthefuture-us/latest/widget.js";

    script.async = true;
    let body = document.getElementsByTagName('body')[0];
    body.appendChild(script);
  }

  componentWillUnmount() {
    console.log('destroy');
    window.rnw.tamaro.destroy();
  }

  render() {
    const { classes } = this.props;

    return (
        <ThemeProvider theme={muiTheme}>
        <div className={classes.container}>

          <div className={classes.tagsubline}>Put the active in activism - join ALPRUN and plant trees with <a href='https://trees.org/' target='_blank' rel='noreferrer'>Trees For The Future</a>.</div>

          <div className="rnw-widget-container">
            <div className={classes.loaderContainer}><Ellipsis color="#FC4C02"/></div>
          </div>

        </div>
        </ThemeProvider>
    )
  }
}

export default withStyles(useStyles)(DonationWidget)