import React from 'react';
import { Link } from "react-router-dom";
import Slider from '@mui/material/Slider';
import Button from '@mui/material/Button';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import {withStyles} from "@material-ui/core/styles";
import Sticker from "./Sticker";
import Background from '../assets/trees.png';
import {FaApplePay, FaGooglePay} from "react-icons/fa";

const muiTheme = createTheme({
  palette: {
    primary: {
      main: "#FC4C02",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: "\"futura_ptdemi\", sans-serif",
          fontSize: '1rem',
          padding: '4px 30px',
          borderRadius: '0',
        },        
      }
    },
    MuiSlider: {
      styleOverrides: {
        rail: {
          height: '15px',
          borderRadius: '6px',
        },
        track: {
          height: '15px',
          borderRadius: '6px',
        },
        thumb: {
          height: '20px',
          border: "5px solid #fff",
        },
      },
    }
  }
});

const useStyles = theme => ({
  panel: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    padding: '40px 0 50px 0',
    color: '#fff',
    background: '#0D263B',
    backgroundImage: 'url(' + Background + ')',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'bottom',
    backgroundSize: '100%',
    alignItems: 'center',
    justifyContent: 'center',

    [theme.breakpoints.up('md')]: {
      backgroundPosition: 'bottom right',
      backgroundSize: 'initial',
      padding: '50px 0 60px 0',
    }
  },
  panelContent: {
    padding: '0 32px',

    [theme.breakpoints.up('md')]: {
      width: '884px',
    }
  },
  taglineStickerContainer: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'space-between',
    }
  },
  taglineContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',


    [theme.breakpoints.up('md')]: {
      alignItems: 'normal',
      textAlign: 'left',
      width: '372px',
    }
  },
  tagline: {
    fontFamily: "\"d-din_condensedregular\", sans-serif",
    fontWeight: 300,
    fontSize: '3.4em',
    textTransform: 'uppercase',
    color: '#fff',
  },
  tagsubline: {
    paddingTop: '14px',
    fontFamily: "\"d-din_condensedregular\", sans-serif",
    fontWeight: 300,
    fontSize: '1.4em',
    color: '#fff',

    '& a': {
      textDecoration: 'underline',
      color: '#FFF',
    },

    '& a:hover': {
      textDecoration: 'none',
      color: '#FFF',
    },
  },
  sticker: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '40px 0',

    [theme.breakpoints.up('md')]: {
      margin: '0',
    }
  },
  sliderContainer: {
    margin: '10px 0 0 0',

    [theme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'space-between',
      margin: '50px 0 0 0',
    }
  },
  slider: {
    width: '100%',

    [theme.breakpoints.up('md')]: {
      padding: '20px 30px 0 30px',
    }
  },
  sliderRangeSmall: {
    display: 'flex',
    justifyContent: 'space-between',

    [theme.breakpoints.up('md')]: {
      display: 'none',
    }
  },
  stats: {
    display: 'flex',
    fontFamily: "\"d-din_condensedregular\", sans-serif",
    color: '#fff',
  },
  detail: {
    paddingLeft: '14px',
    textAlign: 'left'
  },
  stat1: {
    fontWeight: '700',
    fontSize: '4.4em',
    lineHeight: '0.9em',

    [theme.breakpoints.up('md')]: {
      fontSize: '5.7em',
    }
  },
  stat2: {
    fontWeight: '700',
    fontSize: '2.6em',
    lineHeight: '1.1em',

    [theme.breakpoints.up('md')]: {
      fontSize: '3.4em',
    }
  },
  stat3: {
    fontWeight: '500',
    display: 'block',
    fontSize: '1.3em',
    lineHeight: '0.8em',

    [theme.breakpoints.up('md')]: {
      fontSize: '1.4em',
    }
  },
  impact: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: "\"d-din_condensedregular\", sans-serif",
    fontSize: '2.2em',
  },
  donateBtnContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '20px 0 0 0',

    '& a': {
      textDecoration: 'none',
      color: '#FFF',
    },

    '& a:hover': {
      textDecoration: 'none',
      color: '#FFF',
    },

    [theme.breakpoints.up('md')]: {
      display: 'block',
      margin: '0',
      padding: '20px 36px 0 36px',
    }
  },
  walletPayments: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '20px 0 0 0',
    fontSize: '2.8em'
  },
  walletPayment: {
    padding: '0 10px',
  },
  showMobile: {
    display: 'block',

    [theme.breakpoints.up('md')]: {
      display: 'none',
    }
  },
  showDesktop: {
    display: 'none',

    [theme.breakpoints.up('md')]: {
      display: 'block',
    }
  }
});

class DonatePanel extends React.PureComponent {
  constructor(props) {
    super(props);

    let trees = Number(props.trees);
    let localCurrency = this.props.localCurrency;

    this.state = {
      trees: trees,
      donation: Number(trees * localCurrency.cost).toFixed(2),
    }
  }

  componentDidMount() {
  }

  render() {
    const { classes } = this.props;
    let self = this;

    let localCurrency = this.props.localCurrency;

    let donateLink = '/donation/' + this.state.trees;

    function handleChange(event: Event, trees: number | number[]) {
      self.setState({ trees: trees });
      self.setState({ donation: (trees * localCurrency.cost).toFixed(2) });
    };

    return (
        <ThemeProvider theme={muiTheme}>
        
        <div className={classes.panel}>
          <div className={classes.panelContent}>

          <div className={classes.taglineStickerContainer}>

          <div className={classes.taglineContainer}>
            <div className={classes.tagline}>Donate now and<br/>plant more trees</div>
            <div className={classes.tagsubline}>Put the active in activism - join ALPRUN and plant trees with <a href='https://trees.org/' target='_blank' rel='noreferrer'>Trees For The Future</a>.</div>
          </div>

          <div className={classes.sticker}><Sticker treeGoal={self.props.treeGoal} totalTrees={self.props.totalTrees} /></div>

          </div>

          <div className={classes.sliderRangeSmall}>
              <div className={classes.stats}>
                <span className={classes.stat1}>1</span>
                <div className={classes.detail}>
                  <span className={classes.stat2}>TREE</span><span className={classes.stat3}>planted</span>
                </div>
              </div>

              <div className={classes.stats}>
                <span className={classes.stat1}>300</span>
              </div>
          </div>

          <div className={classes.sliderContainer}>

            <div className={classes.showDesktop}>
            <div className={classes.stats}>
              <span className={classes.stat1}>1</span>
              <div className={classes.detail}>
                <span className={classes.stat2}>TREE</span><span className={classes.stat3}>planted</span>
              </div>
            </div>
            </div>

            <div className={classes.slider}>
              <Slider value={self.state.trees} min={1} max={300} onChange={handleChange} />

              <div className={classes.showDesktop}>
                <div className={classes.impact}>
                  {self.state.trees} tree{self.state.trees != 1 && <span>s</span>}&nbsp;= {localCurrency.currencySymbol}{self.state.donation}
                </div>
              </div>
              
            </div>

            <div className={classes.showDesktop}>
            <div className={classes.stats}>
              <span className={classes.stat1}>300</span>
              <div className={classes.detail}>
                <span className={classes.stat2}>TREES</span><span className={classes.stat3}>planted</span>
              </div>
            </div>
            </div>

            <div className={classes.showDesktop}>
            <div className={classes.donateBtnContainer}>
              <Link to={donateLink}><Button variant="contained">Donate</Button></Link>
            </div>
            </div>
          </div>

          <div className={classes.showMobile}>
          <div className={classes.impact}>
            {self.state.trees} tree{self.state.trees != 1 && <span>s</span>}&nbsp;= {localCurrency.currencySymbol}{self.state.donation}
          </div>
          </div>

          <div className={classes.showMobile}>
          <div className={classes.donateBtnContainer}>
            <Link to={donateLink}><Button variant="contained">Donate</Button></Link>
          </div>
          </div>

          <div className={classes.walletPayments}>
            <div className={classes.walletPayment}><FaApplePay /></div>
            <div className={classes.walletPayment}><FaGooglePay /></div>
          </div>

          </div>
        </div>

        </ThemeProvider>
    )
  }
}

export default withStyles(useStyles)(DonatePanel)