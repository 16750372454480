import React from 'react';
import { Link } from "react-router-dom";
import {withStyles} from "@material-ui/core/styles";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Background from '../assets/chris-holgersson-M34n3mIUKS8-unsplash.jpg';
import Logo from "../assets/logo.png";
import Sticker from "./Sticker";
import Button from "@mui/material/Button";
import {FaInstagram} from "react-icons/fa";

const muiTheme = createTheme({
    palette: {
        primary: {
            main: "#FC4C02",
        },
        secondary: {
            main: "#FFF",
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontFamily: "\"futura_ptdemi\", sans-serif",
                    fontSize: '1rem',
                    padding: '4px 20px',
                    borderRadius: '0',
                },
            }
        }
    }
});

const useStyles = theme => ({
    panel: {
        backgroundImage: 'url(' + Background + ')',
        backgroundRepeat: 'no-repeat',
        width: '100%',
        height: '100%',
        minHeight: '100%',
        display: 'flex',
        backgroundSize: 'cover',

        [theme.breakpoints.up('lg')]: {
            backgroundPosition: 'center',
        }
    },
    effect: {
        display: 'flex',
        height: '100%',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'linear-gradient(71.74deg, #0D263B -3.15%, rgba(13, 38, 59, 0) 75%)',

        [theme.breakpoints.up('sm')]: {
            background: 'linear-gradient(71.74deg, #0D263B -3.15%, rgba(13, 38, 59, 0) 55%)',
        },
    },
    panelContent: {
        display: 'flex',
        width: '100%',
        padding: '30px 30px 40px 30px',
        flexDirection: 'column',
        justifyContent: 'center',
        color: '#FFF',

        '& a': {
            textDecoration: 'none',
            color: '#FFF',
        },

        '& a:hover': {
            textDecoration: 'none',
            color: '#FFF',
        },

        [theme.breakpoints.up('md')]: {
            width: '884px',
            padding: '50px 0 120px 0',
        }
    },
    headerContainer: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
    },
    logo: {
        width: '232px',

        [theme.breakpoints.up('md')]: {
            width: '238px'
        }
    },
    HeaderLinksContainer: {
        display: 'none',
        alignItems: 'center',
        fontFamily: "\"futura_ptdemi\", sans-serif",
        textTransform: 'uppercase',

        [theme.breakpoints.up('md')]: {
            display: 'flex',
        }
    },
    link: {
        fontSize: '0.85em',

        '& a': {
            padding: '0 20px 0 0',
            textDecoration: 'underline',
            color: '#FFF',
        },

        '& a:hover': {
            textDecoration: 'none',
            color: '#FFF',
        },
    },
    smallStickerContainer: {
        padding: '30px 0 30px 0',
        display: 'flex',
        justifyContent: 'center',

        [theme.breakpoints.up('md')]: {
            display: 'none',
        }
    },
    title: {
        fontFamily: "\"d-din_condenseddinCnBd\", sans-serif",
        textTransform: 'uppercase',
        fontSize: '3.4em',

        [theme.breakpoints.up('md')]: {
            fontSize: '4.6em',
        }
    },
    description: {
        fontFamily: "\"d-din_condensedregular\", sans-serif",
        fontSize: '1.4em',
        lineHeight: '1.2em',
        padding: '10px 0 0 0',

        [theme.breakpoints.up('md')]: {
            padding: '10px 200px 0 0',
            fontSize: '2.2em',
            lineHeight: '1.2em',
        }
    },
    donateBtnContainer: {
        padding: '20px 0 0 0',
    },
    textStickerContainer: {
        display: 'flex',

        [theme.breakpoints.up('md')]: {
            padding: '70px 0 0 0',
        }
    },
    textContainer: {
    },
    bigStickerContainer: {
        display: 'none',

        [theme.breakpoints.up('md')]: {
            display: 'block',
        }
    }
});

class HeroPanel extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  componentDidMount() {
  }

  render() {
      const { classes } = this.props;
      let self = this;

    return (
        <ThemeProvider theme={muiTheme}>

        <div className={classes.panel}>
            <div className={classes.effect}>
            <div className={classes.panelContent}>

                <div className={classes.headerContainer}>
                    <div><Link to="/"><img className={classes.logo} src={Logo} alt="PROJECT ALPRUN" /></Link></div>
                    <div className={classes.HeaderLinksContainer}>
                        <div className={classes.link}><a href='/about'>Find out more</a></div>
                        <div><Link to="/donation/50"><Button variant="contained">Plant trees</Button></Link></div>
                    </div>
                </div>

                <div className={classes.smallStickerContainer}><Sticker treeGoal={self.props.treeGoal} totalTrees={self.props.totalTrees} /></div>

                <div className={classes.textStickerContainer}>

                    <div className={classes.textContainer}>
                        <div className={classes.title}>Planting trees<br />against climate change</div>
                        <div className={classes.description}>Put the active in activism - <a href='https://www.instagram.com/mallbeury/' target='_blank' rel='noreferrer'>follow ALPRUN</a> and plant trees with Trees For The Future.</div>

                        <div className={classes.donateBtnContainer}>
                            <Link to="/donation/50"><Button variant="contained">Take action now</Button></Link>
                        </div>

                        <div className={classes.donateBtnContainer}>
                            <a href="https://www.instagram.com/mallbeury/" target='_blank' rel='noreferrer'><Button variant="outlined" color="secondary">Follow&nbsp;<FaInstagram /></Button></a>
                        </div>
                    </div>

                    <div className={classes.bigStickerContainer}>
                        <Sticker treeGoal={self.props.treeGoal} totalTrees={self.props.totalTrees} />
                    </div>

                </div>

            </div>
            </div>
        </div>

        </ThemeProvider>
    )
  }
}

export default withStyles(useStyles)(HeroPanel)