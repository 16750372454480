import React from 'react';
import { Link } from "react-router-dom";
import {withStyles} from "@material-ui/core/styles";
import Button from "@mui/material/Button";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Avatar from '../assets/avatar.png';
import PodcastPanel from './PodcastPanel';

const muiTheme = createTheme({
    palette: {
        primary: {
            main: "#FFF",
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontFamily: "\"futura_ptdemi\", sans-serif",
                    fontSize: '1rem',
                    padding: '4px 20px',
                    borderRadius: '0',
                },
            }
        }
    }
});

const useStyles = theme => ({
    panel: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        color: '#fff',
        background: '#0D263B',
        textAlign: 'center',
    },
    panelContent: {
        width: '100%',
        padding: '30px',

        [theme.breakpoints.up('md')]: {
            width: '884px',
        }
    },
    avatarMainContentContainer: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
    },
    avatarMainContent: {
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            width: '552px',
            padding: '40px 0 20px 0',
            textAlign: 'left'
        }
    },
    main: {
        width: '100%',

        [theme.breakpoints.up('md')]: {
            padding: '30px 0 0 40px',
        }
    },
    mottoContainer: {
        textAlign: 'center',
    },
    motto: {
        fontFamily: "\"futura_ptdemi\", sans-serif",
        textTransform: 'uppercase',
        fontSize: '1.4em',
        lineHeight: '1.4em',
        fontWeight: '700',
        color: '#FC4C02',
    },
    mottoText: {
      display: 'block',

        [theme.breakpoints.up('md')]: {
            display: 'inline',
        }
    },
    mottoDivider: {
        display: 'none',

        [theme.breakpoints.up('md')]: {
            display: 'inline',
            height: '34px',
            margin: '0 0 0 30px',
            padding: '0 30px 0 0',
            borderLeft: '2px solid #FC4C02',
        }
    },
    avatar: {
        padding: '20px 0',

        '& img': {
            width: '170px',
        },

        [theme.breakpoints.up('md')]: {
            '& img': {
                width: '208px',
            }
        }
    },
    title: {
        fontFamily: "\"d-din_condenseddinCnBd\", sans-serif",
        textTransform: 'uppercase',
        fontSize: '3.4em',
    },
    place: {
        padding: '10px 0',
        fontFamily: "\"futura_ptdemi\", sans-serif",
        textTransform: 'uppercase',
        fontSize: '0.85em',
    },
    description: {
        fontFamily: "\"d-din_condensedregular\", sans-serif",
        fontSize: '1.4em',
        lineHeight: '1.2em',
    },
    btnContainer: {
        padding: '20px 0 0 0',

        '& a': {
            textDecoration: 'none',
        },

        '& a:hover': {
            textDecoration: 'none',
        },
    },
    loaderContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }
});

class AboutPanel extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  componentDidMount() {
  }

  render() {
    const { classes } = this.props;
    let treeGoal = this.props.treeGoal.toLocaleString('en-US');

    return (
        <ThemeProvider theme={muiTheme}>

        <div className={classes.panel}>
            <div className={classes.panelContent}>

                <div className={classes.mottoContainer}>
                    <div className={classes.motto}><div className={classes.mottoText}>Mountain Running</div><div className={classes.mottoDivider}></div><div className={classes.mottoText}>Planet Fundraising</div></div>
                </div>

                <div className={classes.avatarMainContentContainer}>
                <div className={classes.avatarMainContent}>

                    <div className={classes.avatar}><img src={Avatar} /></div>

                    <div className={classes.main}>
                        <div className={classes.title}>Follow Matt on the adventure!</div>
                        <div className={classes.place}>Start Zurich July 19 - finish Trieste August 17</div>
                        <div className={classes.description}>“My challenge is to run 1,000km in 30 days, crossing the Alps from North to South, fundraising for {treeGoal} trees!”</div>
                        <div className={classes.btnContainer}><Link to="/about"><Button variant="contained">Learn More</Button></Link></div>
                    </div>

                </div>
                </div>

                <PodcastPanel />

            </div>
        </div>

        </ThemeProvider>
    )
  }
}

export default withStyles(useStyles)(AboutPanel)