import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import TextTransition, {presets} from "react-text-transition";

const useStyles = theme => ({
    panel: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        textAlign: 'center'
    },
    panelContent: {
        display: 'flex',
        position: 'relative',
        width: '100%',
        height: '114px',
        overflow: 'hidden',
        alignItems: 'center',
        justifyContent: 'center',

        [theme.breakpoints.up('md')]: {
            width: '884px',
            height: 'initial',
            padding: '40px 0',
            justifyContent: 'space-between',
        }
    },
    stats: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '240px',
        fontFamily: "\"d-din_condensedregular\", sans-serif",
        padding: '14px',
        color: '#0D263B',
        background: '#FFF',

        [theme.breakpoints.up('md')]: {
            width: 'initial',
            padding: '0',
        }
    },
    statsDivider: {
        display: 'inline',
        height: '66px',
        margin: '10px 0 0 0',
        padding: '0 10px 0 0',
        borderLeft: '2px solid #0D263B',
    },
    detail: {
        paddingLeft: '14px',
        textAlign: 'left'
    },
    stat1: {
        fontWeight: '700',
        fontSize: '4.5em',
        lineHeight: '0.9em',

        [theme.breakpoints.up('md')]: {
            fontSize: '5.8em',
        },
    },
    stat2: {
        fontWeight: '700',
        fontSize: '2.6em',
        lineHeight: '0.9em',

        [theme.breakpoints.up('md')]: {
            fontSize: '3.4em',
        },
    },
    stat3: {
        fontWeight: '500',
        display: 'block',
        fontSize: '1.3em',
        lineHeight: '0.9em',

        [theme.breakpoints.up('md')]: {
            fontSize: '1.5em',
        },
    },
    statsMobile: {
        display: 'flex',
        position: 'absolute',
        top: '14px',

        [theme.breakpoints.up('md')]: {
            display: 'none',
        }
    },
    statsDesktop: {
        display: 'none',

        [theme.breakpoints.up('md')]: {
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
        }
    }
});

const TEXTS1 = [
    "30",
    "1,000",
    "0",
];

const TEXTS2 = [
    "DAYS",
    "KM",
    "TREES",
];

const TEXTS3 = [
    "in the Alps",
    "to run",
    "to plant",
];

class StatsPanel extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
        index: 0
    }

    this.intervalID = null;
  }

  componentDidMount() {
      this.intervalID = setInterval(() => {
          let index = this.state.index;
          this.setState({index: index + 1});
      }, 3000);
  }

  componentWillUnmount() {
      clearInterval(this.intervalID);
  }

  render() {
      const { classes } = this.props;
      let index = this.state.index;
      let treeGoal = this.props.treeGoal.toLocaleString('en-US');
      TEXTS1[2] = treeGoal;

    return (
        <div className={classes.panel}>
            <div className={classes.panelContent}>

                <div className={classes.statsDesktop}>
                    <div className={classes.stats}>
                        <span className={classes.stat1}>{TEXTS1[0]}</span>
                        <div className={classes.detail}>
                            <span className={classes.stat2}>{TEXTS2[0]}</span><span className={classes.stat3}>{TEXTS3[0]}</span>
                        </div>
                    </div>
                    <div className={classes.statsDivider}></div>
                    <div className={classes.stats}>
                        <span className={classes.stat1}>{TEXTS1[1]}</span>
                        <div className={classes.detail}>
                            <span className={classes.stat2}>{TEXTS2[1]}</span><span className={classes.stat3}>{TEXTS3[1]}</span>
                        </div>
                    </div>
                    <div className={classes.statsDivider}></div>
                    <div className={classes.stats}>
                        <span className={classes.stat1}>{TEXTS1[2]}</span>
                        <div className={classes.detail}>
                            <span className={classes.stat2}>{TEXTS2[2]}</span><span className={classes.stat3}>{TEXTS3[2]}</span>
                        </div>
                    </div>
                </div>

                <div className={classes.statsMobile}>
                    <TextTransition delay={3000} springConfig={presets.gentle}>
                        <div className={classes.stats}>
                            <span className={classes.stat1}>{TEXTS1[index % TEXTS1.length]}</span>
                            <div className={classes.detail}>
                                <span className={classes.stat2}>{TEXTS2[index % TEXTS2.length]}</span><span className={classes.stat3}>{TEXTS3[index % TEXTS3.length]}</span>
                            </div>
                        </div>
                    </TextTransition>
                </div>
            </div>
        </div>
    )
  }
}

export default withStyles(useStyles)(StatsPanel)