import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import Button from "@mui/material/Button";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Background from "../assets/sergei-a-heLWtuAN3c-unsplash1.png";
import Logo from "../assets/active_giving.png";

const muiTheme = createTheme({
    palette: {
        primary: {
            main: "#FC4C02",
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontFamily: "\"futura_ptdemi\", sans-serif",
                    fontSize: '1rem',
                    padding: '4px 20px',
                    borderRadius: '0',
                },
            }
        }
    }
});

const useStyles = theme => ({
    panel: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundImage: 'url(' + Background + ')',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '100%',
        minHeight: '100%',
    },
    effect: {
        display: 'flex',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        background: 'rgba(89, 129, 163, 0.7)'
    },
    panelContent: {
        display: 'flex',
        padding: '50px 32px',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        color: '#FFF',

        [theme.breakpoints.up('md')]: {
            width: '884px',
        }
    },
    logo: {
        padding: '0 0 20px 0',
    },
    title: {
        fontFamily: "\"d-din_condenseddinCnBd\", sans-serif",
        textTransform: 'uppercase',
        fontSize: '3.44em',
        textShadow: '0px 4px 4px rgba(0,0,0,0.25)',
    },
    appBtnContainer: {
      padding: '20px 0 30px 0',
    },
    description: {
        fontFamily: "\"d-din_condensedregular\", sans-serif",
        fontSize: '1.4em',
        lineHeight: '1.2em',

        [theme.breakpoints.up('md')]: {
            width: '460px',
        }
    },
});

class ActiveGivingPanel extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  componentDidMount() {
  }

  render() {
    const { classes } = this.props;

    return (
        <ThemeProvider theme={muiTheme}>

        <div className={classes.panel}>
            <div className={classes.effect}>
                <div className={classes.panelContent}>

                    <div className={classes.logo}><img src={Logo} /></div>
                    <div className={classes.title}>Get active and plant trees with<br />the active giving app</div>
                    <div className={classes.appBtnContainer}>
                        <Button variant='contained' href='https://www.activegiving.de' target='_blank' rel='noreferrer'>Get the app</Button>
                    </div>
                    <div className={classes.description}>Get the Active Giving app and trees get planted<br />every move you make, that’s it!</div>

                </div>
            </div>
        </div>

        </ThemeProvider>
    )
  }
}

export default withStyles(useStyles)(ActiveGivingPanel)