import React from 'react';
import { withStyles } from '@material-ui/core/styles';

const useStyles = theme => ({
  photo: {
    width: '100%',

    [theme.breakpoints.up('md')]: {
      width: '380px',
    }
  }
});

class ActivityPhoto extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
  }

  render() {
    const { classes } = this.props;
    let photoURL = this.props.photoURL;

    return (
        <img className={classes.photo} src={photoURL} />
    )
  }
}

export default withStyles(useStyles)(ActivityPhoto)
