import React from 'react';
import Odometer from 'react-odometerjs';
import { withStyles } from '@material-ui/core/styles';
import 'odometer/themes/odometer-theme-default.css';

const useStyles = theme => ({
  sticker: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    background: 'linear-gradient(180deg, #FC4C02 0%, #FF996E 100%)',
    borderRadius: '50%',
    border: '8px solid #fff',
    width: '180px',
    height: '180px',
  },
  data: {
    color: '#fff',
    textTransform: 'uppercase'
  },
  total: {
    fontFamily: "\"d-din_condensedregular\", sans-serif",
    fontSize: '4.3em',
    lineHeight: '1em'
  },
  trees: {
    fontFamily: "\"d-din_condensedregular\", sans-serif",
    fontSize: '2em',
    lineHeight: '1em',
    padding: '2px 0px 6px 0px'
  },
  goal: {
    fontFamily: "\"futura_ptdemi\", sans-serif",
    lineHeight: '0.85em'
  }
});

class Sticker extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      totalTrees: 0
    };
  }

  componentDidMount() {
    let self = this;

    this.intervalID = setInterval(() => {
      this.setState({totalTrees: self.props.totalTrees});
    }, 1000);
  }

  render() {
    const { classes } = this.props;
    let self = this;
    let treeGoal = this.props.treeGoal.toLocaleString('en-US');

    return (
        <div className={classes.sticker}>
          <div className={classes.data}>
            <div className={classes.total}>
              <Odometer animation="count" format="(,ddd),dd" duration={ 4000 } value={ self.state.totalTrees }/>
            </div>
            <div className={classes.trees}>trees planted</div>
            <div className={classes.goal}>of goal {treeGoal}</div>
          </div>
        </div>
    )
  }
}

export default withStyles(useStyles)(Sticker)
