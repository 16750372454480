import React from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { withStyles } from '@material-ui/core/styles';
import {Link, useHistory} from "react-router-dom";
import DonationWidget from './DonationWidget';
import Background from '../assets/trees.png';
import { FaArrowCircleLeft } from 'react-icons/fa';

const muiTheme = createTheme({
    palette: {
        primary: {
            main: "#FC4C02",
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontFamily: "\"futura_ptdemi\", sans-serif",
                    fontSize: '1.2rem',
                    padding: '4px 30px',
                    borderRadius: '0',
                },
            }
        },
    }
});

const useStyles = theme => ({
    panel: {
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
        padding: '40px 0 50px 0',
        color: '#fff',
        background: '#0D263B',
        backgroundImage: 'url(' + Background + ')',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'bottom',
        backgroundSize: '100%',
        alignItems: 'center',
        justifyContent: 'center',

        [theme.breakpoints.up('md')]: {
            backgroundPosition: 'bottom right',
            backgroundSize: 'initial',
            padding: '50px 0 60px 0',
        }
    },
    panelContent: {
        padding: '0 32px',

        [theme.breakpoints.up('md')]: {
            width: '884px',
        }
    },
    taglineContainer: {
        display: 'flex',

        [theme.breakpoints.up('md')]: {
            justifyContent: 'space-between',
        }
    },
    tagline: {
        fontFamily: "\"d-din_condensedregular\", sans-serif",
        fontWeight: 300,
        fontSize: '3.4em',
        textTransform: 'uppercase',
        color: '#fff',

        [theme.breakpoints.up('sm')]: {
            width: '340px',
        }
    },
    navBack: {
        fontSize: '3em',
        color: '#FFF',
        padding: '10px 0 0 10px',

        '& a': {
            textDecoration: 'none',
            color: '#FFF',
        },

        '& a:hover': {
            textDecoration: 'none',
            color: '#FFF',
        },
    },
    donationWidget: {
        display: 'flex',
        padding: '30px 0 0 0',
        width: '100%',
        minHeight: '400px',
        alignItems: 'center',
        justifyContent: 'center',

        [theme.breakpoints.up('md')]: {
            padding: '50px 0 0 0',
        }
    }
});

class DonationPagePanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
  }
  
  render() {
    const { classes } = this.props;
    let self = this;
    let homeLink = '/' + this.props.trees;
    let trees = this.props.trees;

    return (
        <ThemeProvider theme={muiTheme}>
            <div className={classes.panel}>
                <div className={classes.panelContent}>

                    <div className={classes.taglineContainer}>
                        <div className={classes.tagline}>Donate now and plant more trees</div>
                        <div className={classes.navBack}><Link to={homeLink}><FaArrowCircleLeft /></Link></div>
                    </div>

                    <div className={classes.donationWidget}>
                        <DonationWidget config={self.props.config} trees={trees} />
                    </div>

                </div>
            </div>
        </ThemeProvider>
    );
  }
}

export default withStyles(useStyles)(DonationPagePanel)