import React from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import {withStyles} from "@material-ui/core/styles";
import LocationMapPanel from './LocationMapPanel';
import StatsProgressPanel from './StatsProgressPanel';

const muiTheme = createTheme({
  palette: {
    primary: {
      main: "#FC4C02",
    },
  },
});

const useStyles = theme => ({
  panel: {
  },
})

class LocationPanel extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      route: [],
      currentLocation: [props.lastKnownLocation.Latitude, props.lastKnownLocation.Longitude],
      startDate: props.startDate,
      totalDays: props.totalDays
    };

    this.fPrevLocationLat = 0;
    this.fPrevLocationLng = 0;
  }

  componentDidMount() {
    var self = this;

    function getLocation() {
      // get location
      fetch('/api/location')
          .then(response => response.json())
          .then(data => {
            // has location moved?
            if (data.Latitude.toFixed(3) != self.fPrevLocationLat.toFixed(3) || data.Longitude.toFixed(3) != self.fPrevLocationLng.toFixed(3)) {
                console.log('getLocation MOVED');
                self.setState({ currentLocation: [data.Latitude, data.Longitude] });

                self.fPrevLocationLat = data.Latitude;
                self.fPrevLocationLng = data.Longitude;
            }

            // start timer
            setTimeout(() => {
              // update location
              getLocation();
            }, 10000);
          })
    }

    function getRoute() {
        let strRoute = '3099542563367960246'; // PAR - WIP
//      let strRoute = '3078854356973602810'; // Home big loop
//      let strRoute = '3085096314085549794'; // PAR - Williamstown
//      let strRoute = '3107192095614666316'; // PAR - Ultra Journey

      fetch('/strava/athlete/270394/route/' + strRoute)
          .then(response => response.json())
          .then(data => {
            self.setState({ route: data.points });

            getLocation();
          });
    }
    getRoute();
  }

  render() {
    const { classes } = this.props;
    let self = this;

    return (
        <ThemeProvider theme={muiTheme}>

        <LocationMapPanel route={self.state.route} lastKnownLocation={self.props.lastKnownLocation} currentLocation={self.state.currentLocation} />
        <StatsProgressPanel route={self.state.route} currentLocation={self.state.currentLocation} startDate={self.state.startDate} totalDays={self.state.totalDays} />

        </ThemeProvider>
    )
  }
}

export default withStyles(useStyles)(LocationPanel)