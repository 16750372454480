import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import StravaLogo from "../assets/api_logo_pwrdBy_strava_stack_light.svg";
import RaiseNowLogo from "../assets/Logotype_RaiseNow.svg";

const useStyles = theme => ({
    panel: {
        width: '100%',
    },
    panelContent: {
        width: '100%',
        padding: '20px 0',

        [theme.breakpoints.up('md')]: {
            padding: '40px 0 30px 0',
        }
    },
    logos: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',

        [theme.breakpoints.up('md')]: {
            justifyContent: 'space-between',
        }
    },
    StravaLogo: {
        width: '100px',
        padding: '0 10px 0 10px',

        '& img': {
            width: '100%'
        },

        [theme.breakpoints.up('md')]: {
            padding: '0 10px 0 30px',
        }
    },
    RaiseNowLogo: {
        width: '120px',
        padding: '8px 10px 0 10px',

        '& img': {
            width: '100%'
        },

        [theme.breakpoints.up('md')]: {
            padding: '0 30px 0 10px',
        }
    },
    links: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        marginTop: '0',
        padding: '10px 0 0 0',
        fontFamily: "\"futura_ptdemi\", sans-serif",
        fontSize: '0.85em',
        textTransform: 'uppercase',

        '& a': {
            textDecoration: 'underline',
            color: '#0D263B',
        },

        '& a:hover': {
            textDecoration: 'none',
            color: '#0D263B',
        },

        [theme.breakpoints.up('md')]: {
            marginTop: '-20px',
            padding: '0',
        }
    },
    link: {
        padding: '0 10px',
    }
});

class FooterPanel extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  componentDidMount() {
  }

  render() {
      const { classes } = this.props;

    return (
        <div className={classes.panel}>
            <div className={classes.panelContent}>
                <div className={classes.logos}>
                    <div className={classes.StravaLogo}><a href='https://strava.com/' target='_blank' rel='noreferrer'><img src={StravaLogo} /></a></div>
                    <div className={classes.RaiseNowLogo}><a href='http://raisenow.com/' target='_blank' rel='noreferrer'><img src={RaiseNowLogo} /></a></div>
                </div>

                <div className={classes.links}>
                    <div className={classes.link}><a href='https://www.instagram.com/mallbeury/' target='_blank' rel='noreferrer'>Follow on Instagram</a></div>
                    <div className={classes.link}><a href='https://www.strava.com/athletes/270394' target='_blank' rel='noreferrer'>Follow on Strava</a></div>
                </div>
            </div>
        </div>
    )
  }
}

export default withStyles(useStyles)(FooterPanel)