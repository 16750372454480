import React from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import HoldingPage from './components/HoldingPage';
import WelcomePage from './components/WelcomePage';
import AboutPage from './components/AboutPage';
import DonationPage from './components/DonationPage';
import './stylesheet.css';
import './App.css';

function App() {
//    <Route path="/" exact component={HoldingPage} />

  return (
    <Router>
      <Switch>
        <Route path="/" exact component={WelcomePage} />
        <Route path="/about" exact component={AboutPage} />
        <Route path="/wip" exact component={WelcomePage} />
        <Route path="/wip/:trees" exact component={WelcomePage} />
        <Route path="/donation/:trees" component={DonationPage}/>
        <Route path="/:trees" component={WelcomePage}/>
      </Switch>
    </Router>
  );
}

export default App;
